import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Contact extends React.Component{
  constructor(){
    super();

    this.firstName = React.createRef();
    this.lastName = React.createRef();
    this.email = React.createRef();
    this.phone = React.createRef();
    this.message = React.createRef();

    this.state = {
      uid: '',
      firstNameErrorMessage:"",
      lastNameErrorMessage:"",
      emailErrorMessage:"",
      formErrorMessage: "",
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      firstNameErrorMessage:"",
      lastNameErrorMessage:"",
      emailErrorMessage:"",
      formErrorMessage: ""
    })

    if(!this.firstName.current.value || !this.lastName.current.value
      || !this.email.current.value){

      if(!this.firstName.current.value){
        this.setState({
          firstNameErrorMessage:"Ce champ est obligatoire.",
          formErrorMessage:"Un ou plusieurs champs contiennent une erreur."
        })
      } 
      
      if(!this.lastName.current.value){
        this.setState({
          lastNameErrorMessage:"Ce champ est obligatoire.",
          formErrorMessage:"Un ou plusieurs champs contiennent une erreur."
        })
      } 
      
      if(!this.email.current.value){
        this.setState({
          emailErrorMessage:"Ce champ est obligatoire.",
          formErrorMessage:"Un ou plusieurs champs contiennent une erreur."
        })
      }
    } else{
      const form = event.target;
      const data = new FormData(form);
      const xhr = new XMLHttpRequest();
      const number = Math.random();
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset();
          this.setState({
            uid: xhr.status + number,
          })
          console.log("Success");
        } else {
          console.log("Error");
        }
      };
      xhr.send(data);
    }
      
  }
  
  render() {
    if(this.state.uid){
			return(
        <Layout>
          <SEO title="Fabien Naté - Contact" />
          <h3 className='underline-title'>MERCI</h3>
          <br />
          <br />
          <div className="table-responsive">
            <p>
            Merci, Nous vous contacterons sous peu. 
            </p>
          </div>
          <br />
          <br />
          <Link to="/">Biographie</Link>
        </Layout>
			)
    }
    
    return (
      <Layout>
        <SEO title="Fabien Naté - Contact" />
        <h3 className='underline-title'>AU PLAISIR DE VOUS RÉPONDRE</h3>
        <br />
        <br />
        <div className="table-responsive">
          <form onSubmit={this.handleSubmit} action="https://formspree.io/f/mzbkkajp" method="POST" >
            <label htmlFor="Prénom" className="text-white">Prénom</label>
            <div className="input-group mb-3">              
              <div className="input-group-prepend">
                <span className="input-group-text" id="firstName-addon"><i className="fas fa-user"></i></span>
              </div>
              <input type="text" name="firstName" ref={this.firstName} placeholder="Prénom" className={this.state.firstNameErrorMessage 
                ? "is-invalid form-control topandbottonrightcorner" : "form-control topandbottonrightcorner"} id="firstName" aria-describedby="Prénom" required/>  
                <div className="invalid-feedback">
                  {this.state.firstNameErrorMessage} 
                </div>              
            </div>            
            <label htmlFor="Nom" className="text-white">Nom</label>
            <div className="input-group mb-3">              
              <div className="input-group-prepend">
                <span className="input-group-text" id="lastName-addon"><i className="fas fa-user"></i></span>
              </div>
              <input type="text" name="lastName" ref={this.lastName} placeholder="Nom" className={this.state.lastNameErrorMessage 
                ? "is-invalid form-control topandbottonrightcorner" : "form-control topandbottonrightcorner"} id="lastName" aria-describedby="Nom" required/>
              <div className="invalid-feedback">
                {this.state.lastNameErrorMessage} 
              </div>
            </div>
            <label htmlFor="Courriel" className="text-white">Courriel</label>
            <div className="input-group mb-3">              
              <div className="input-group-prepend">
                <span className="input-group-text" id="email-addon">@</span>
              </div>              
              <input type="email" name="email" ref={this.email} placeholder="Courriel" className={this.state.emailErrorMessage 
                ? "is-invalid form-control topandbottonrightcorner" : "form-control topandbottonrightcorner"} id="email" aria-describedby="Courriel" required/>
              <div className="invalid-feedback">
                {this.state.emailErrorMessage} 
              </div>
            </div>
            <label htmlFor="Téléphone" className="text-white">Téléphone</label>
            <div className="input-group mb-3">              
              <div className="input-group-prepend">
                <span className="input-group-text" id="phone-addon"><i className="fas fa-mobile-alt"></i></span>
              </div>              
              <input type="text" name="phone" ref={this.phone} placeholder="Téléphone" className="form-control" id="phone" 
                aria-describedby="Téléphone"/>
            </div>             
            <div className="form-group">
              <label htmlFor="Message" className="text-white">Message</label>
              <textarea className="form-control" name="message" ref={this.message} id="textareaMessage" rows="9"></textarea>
            </div>
            <button type="submit" className={this.state.formErrorMessage ? "is-invalid btn btn-primary" : 
              "btn btn-primary"}>Envoyer&nbsp;<i className="fas fa-chevron-circle-right"></i></button>
            <div className="invalid-feedback">
              {this.state.formErrorMessage} 
            </div>
          </form>
        </div>
        <br />
        <br />
        <Link to="/">Biographie</Link>
      </Layout>
    )
  }
}

export default Contact;
